import React from "react";
import './Loading.css';

export default function Loading() {
  return (
    <div className="loading-screen">
      <div className="loading">
        <span className="loading-indicator">
          <span className="icon icon-name-loading"></span>
        </span>
      </div>
    </div>
  );
}
