const DEFAULT_LANGUAGE = "en";

const LABELS = {
  en: {
    "confirm.success.message": "YOUR REGISTRATION IS NOW COMPLETE. THANK YOU.",
    "confirm.failure.message":
      "WE COULD NOT FINALIZE YOUR REGISTRATION. PLEASE ASK GUIDANCE TO YOUR ADVISOR.",
    "confirm.expired.message":
      "THE LINK IS EXPIRED. PLEASE CONTACT YOUR ADVISOR TO REGISTER.",
  },
  ar: {
    "confirm.success.message": "تسجيلكم مكتمل الآن. شكرا لكم.",
    "confirm.failure.message":
      "لا يمكننا إنهاء تسجيلكم. يرجى طلب التوجيه من مستشاركم.",
    "confirm.expired.message":
      "انتهت صلاحية الرابط. يرجى الاتصال بمستشاركم للتسجيل.",
  },
  cs: {
    "confirm.success.message":
      "VAŠE REGISTRACE JE NYNÍ DOKONČENA. DĚKUJEME VÁM.",
    "confirm.failure.message":
      "REGISTRACI JSME NEMOHLI DOKONČIT. VYŽÁDEJTE SI PROSÍM POKYNY OD SVÉHO PORADCE.",
    "confirm.expired.message":
      "PLATNOST ODKAZU VYPRŠELA. PROSÍM POŽÁDEJTE O REGISTRACI SVÉHO PORADCE.",
  },
  de: {
    "confirm.success.message": "IHRE ANMELDUNG IST ABGESCHLOSSEN. VIELEN DANK.",
    "confirm.failure.message":
      "WIR KONNTEN IHRE ANMELDUNG NICHT ABSCHLIESSEN. WENDEN SIE SICH BITTE AN IHREN BERATER.",
    "confirm.expired.message":
      "DER LINK IST ABGELAUFEN. BITTE WENDEN SIE SICH FÜR DIE ANMELDUNG AN IHREN BERATER.",
  },
  es: {
    "confirm.success.message": "SE HA COMPLETADO SU REGISTRO. GRACIAS.",
    "confirm.failure.message":
      "NO HEMOS PODIDO COMPLETAR SU REGISTRO. SOLICITE ORIENTACIÓN A SU ASESOR.",
    "confirm.expired.message":
      "EL ENLACE HA CADUCADO. PÓNGASE EN CONTACTO CON SU ASESOR PARA REGISTRARSE.",
  },
  it: {
    "confirm.success.message": "L’ISCRIZIONE È STATA EFFETTUATA. GRAZIE.",
    "confirm.failure.message":
      "NON È STATO POSSIBILE FINALIZZARE LA SUA ISCRIZIONE. LA INVITIAMO A CONTATTARE UN CONSULENTE.",
    "confirm.expired.message":
      "IL LINK NON È PIÙ VALIDO. PER ISCRIVERSI, LA INVITIAMO A CONTATTARE UN CONSULENTE.",
  },
  ja: {
    "confirm.success.message": "登録が完了されました。ありがとうございました。",
    "confirm.failure.message":
      "お客さまの登録を完了できませんでした。アドバイザーにお問い合わせください。",
    "confirm.expired.message":
      "このリンクは期限切れです。アドバイザーにお問い合わせください。",
  },
  ms: {
    "confirm.success.message": "PENDAFTARAN ANDA TELAH SELESAI. TERIMA KASIH.",
    "confirm.failure.message":
      "KAMI TIDAK DAPAT MENERUSKAN PENDAFTARAN ANDA. SILA MOHON PANDUAN DARIPADA PENASIHAT ANDA.",
    "confirm.expired.message":
      "PAUTAN TELAH TAMAT TEMPOH. SILA HUBUNGI PENASIHAT ANDA UNTUK MENDAFTAR.",
  },
  nl: {
    "confirm.success.message": "U BENT NU INGESCHREVEN. BEDANKT.",
    "confirm.failure.message":
      "WIJ HEBBEN UW INSCHRIJVING NIET KUNNEN AFRONDEN VRAAG UW ADVISEUR OM HULP.",
    "confirm.expired.message":
      "DEZE LINK IS NIET MEER GELDIG. NEEM CONTACT OP MET UW ADVISEUR OM U TE REGISTREREN.",
  },
  sv: {
    "confirm.success.message": "REGISTRERINGEN ÄR KLAR. TACK!",
    "confirm.failure.message":
      "DET GICK INTE ATT SLUTFÖRA REGISTRERINGEN. BE DIN RÅDGIVARE OM HJÄLP.",
    "confirm.expired.message":
      "LÄNKEN HAR UPPHÖRT ATT GÄLLA. KONTAKTA DIN RÅDGIVARE FÖR ATT REGISTRERA DIG.",
  },
  "zh-Hans": {
    "confirm.success.message": "您的注册已经完成。谢谢您。",
    "confirm.failure.message": "我们无法完成您的注册。请咨询您的顾问。",
    "confirm.expired.message": "链接已失效。请联系您的顾问进行注册。",
  },
  "zh-Hant": {
    "confirm.success.message": "您的註冊現已完成。謝謝。",
    "confirm.failure.message": "我們無法完成您的註冊。請向您的顧問尋求協助。",
    "confirm.expired.message": "連結已過期。請聯絡您的顧問以進行註冊。",
  },
};

export function translate(lang, key) {
  lang = (lang || "").toLowerCase();
  const labels = LABELS[lang] || LABELS[DEFAULT_LANGUAGE];
  return labels[key] || key;
}
