/*
 * Celine enspoint (https://talend-uat.celine.net/services/rest/dcc/v1/clients/confirm)
 * is available only for specific (static) ip addresses, so we use a proxy:
 * https://celine.talend.uat.clienteling.lvmhda.com
 */

const stage = window.location.host.indexOf("staging") >= 0 ? "uat" : "dev";
const ACCESS_TOKEN_ENDPOINT =
  //"http://localhost:5000/confirmGetToken";
  stage === "uat"
    ? "https://europe-west1-grp-dev-clix-prj-clienteling.cloudfunctions.net/celine-ddc-lp-be-staging-function"
    : "https://europe-west1-grp-dev-clix-prj-clienteling.cloudfunctions.net/celine-ddc-lp-be-dev-function";
const CONFIRM_ENDPOINT =
  //"http://localhost:5000/confirm";
  "https://celine.talend.uat.clienteling.lvmhda.com/services/rest/dcc/v1/clients/confirm";

async function getAccessToken() {
  const response = await fetch(ACCESS_TOKEN_ENDPOINT).then((res) => {
    if (res.status !== 200) {
      throw res.statusText;
    }
    return res.json();
  });
  return response.accessToken;
}

export async function confirmClient(hashcode) {
  const accessToken = await getAccessToken();
  const endpoint = CONFIRM_ENDPOINT + "?hashcode=" + hashcode;
  return fetch(endpoint, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json;charset=utf-8",
    },
  }).then((res) => {
    return res.json();
  });
}
