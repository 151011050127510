import React, { useState } from "react";
import Loading from "../Loading";
import { confirmClient } from "../../utils/api";
import { translate as t } from "../../utils/translations";
import "./App.css";

const urlParams = new URLSearchParams(window.location.search);
const hashcode = urlParams.get("hashcode");
const lang = urlParams.get("preferredLanguage");

const callConfirm = confirmClient(hashcode);

export default function App() {
  const [message, setMessage] = useState();

  if (hashcode) {
    callConfirm
      .then((res) => {
        //console.log(res);
        if (res.status === "fail" || res.status === "error") {
          const errorMessage =
            (res.data && res.data.result && res.data.result.err) || res.message;
          console.log("errorMessage:", errorMessage);
          if (errorMessage.indexOf("expired") >= 0) {
            setMessage("confirm.expired.message");
          } else {
            setMessage("confirm.failure.message");
          }
        } else {
          setMessage("confirm.success.message");
        }
      })
      .catch((err) => {
        console.log("confirmClient: error:", err);
        setMessage("confirm.failure.message");
      });
  } else {
    if (!message) {
      setMessage("confirm.expired.message");
    }
  }

  return (
    <div className="App">
      {!message && <Loading />}
      <h2 className="title">{t(lang, message)}</h2>
    </div>
  );
}
